@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1, h2, h3 {
    @apply font-lemonmilk;
  }
  p {
    @apply font-raleway;
  }
  body {
    @apply font-caviar;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded-lg text-white bg-red-600 hover:bg-red-700 transition duration-300 ease-in-out;
  }
}

@layer utilities {
  .bg-dark-blue {
    background-color: #00094B;
  }
  .bg-light-blue {
    background-color: #6699FF;
  }
  .font-poppins-extrabold {
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
  }
  .font-montez {
    font-family: 'Montez', cursive;
  }
  .text-shadow {
    text-shadow: 2px 2px 4px #000000; /* Contorno de color very-dark-black */
  }
}